import {
  automaticAccess,
  bombRenovation,
  intercom,
  secureGate,
} from "../assets/improvements-image";
import { InformativeCardProps } from "../types/InformativeCardProps";

export const proyectsCardContent: InformativeCardProps[] = [
  {
    image: intercom,
    title: "Citofonía inteligente",
    modal:
      "Modernizamos el sistema de citofonía con tecnología inteligente, permitiendo una comunicación más segura y eficiente entre los residentes y el personal de administración. Esta actualización ofrece funcionalidades avanzadas como video llamada, control remoto y notificaciones instantáneas, mejorando la seguridad y la comodidad en el edificio.",
  },
  {
    image: automaticAccess,
    title: "Acceso automático",
    modal:
      "Implementamos un sistema de acceso automático mediante huella digital, facilitando la entrada al edificio de manera rápida y segura. Este innovador sistema elimina la necesidad de llaves físicas y permite un control preciso sobre quién accede al edificio, aumentando la seguridad y la comodidad para los residentes.",
  },
  {
    image: secureGate,
    title: "Portón Antiportonazo",
    modal:
      "Reemplazamos el antiguo portón con un modelo antiportonazo, diseñado para prevenir el acceso no autorizado y mejorar la seguridad del edificio. Este portón cuenta con mecanismos avanzados que refuerzan la protección contra intentos de robo, asegurando una entrada más segura para todos los residentes.",
  },
  {
    image: bombRenovation,
    title: "Renovación Bombas de Calor + Subsidio Metrogas",
    modal:
      "Reemplazamos el antiguo portón con un modelo antiportonazo, diseñado para prevenir el acceso no autorizado y mejorar la seguridad del edificio. Este portón cuenta con mecanismos avanzados que refuerzan la protección contra intentos de robo, asegurando una entrada más segura para todos los residentes.",
  },
];
