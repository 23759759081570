import { bomb, carCharger, cleanEnergy } from "../assets/improvements-image";
import { InformativeCardProps } from "../types/InformativeCardProps";

export const energeticEfficiency: InformativeCardProps[] = [
  {
    image: bomb,
    title: "Bombas de calor",
    modal:
      "Las bombas de calor son una solución eficiente y ecológica para la climatización de tu comunidad. Utilizan tecnología avanzada para extraer calor del aire exterior y transferirlo al interior, reduciendo el consumo energético y los costos asociados. Con nuestra instalación, podrás disfrutar de un ambiente cómodo y sostenible durante todo el año.",
  },
  {
    image: carCharger,
    title: "Cargador vehículos eléctricos",
    modal:
      "Las bombas de calor son una solución eficiente y ecológica para la climatización de tu comunidad. Utilizan tecnología avanzada para extraer calor del aire exterior y transferirlo al interior, reduciendo el consumo energético y los costos asociados. Con nuestra instalación, podrás disfrutar de un ambiente cómodo y sostenible durante todo el año.",
  },
  {
    image: cleanEnergy,
    title: "Energía renovable  en espacios comunes",
    modal:
      "Las bombas de calor son una solución eficiente y ecológica para la climatización de tu comunidad. Utilizan tecnología avanzada para extraer calor del aire exterior y transferirlo al interior, reduciendo el consumo energético y los costos asociados. Con nuestra instalación, podrás disfrutar de un ambiente cómodo y sostenible durante todo el año.",
  },
];
