import {
  Button,
  Card,
  CardBody,
  Center,
  Heading,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { InformativeCardProps } from "../../../types/InformativeCardProps";
import ImprovesModal from "../ImprovesModal";
// import ImprovesModal from "../Modal";

const PhotoCard = ({
  image,
  title,
  description,
  modal,
}: InformativeCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Card
      flexDirection={"column"}
      w={["289px", "326px", "326px", "326px"]}
      borderRadius={10}
      bg={"secondary.100"}
    >
      <Center>
        <Image
          src={image}
          alt={title}
          borderRadius={10}
          objectFit="cover"
          h={"326px"}
          w={"100%"}
        />
      </Center>

      <CardBody>
        {!modal && (
          <Heading fontSize="sm" textAlign={"center"} color={"text.primary"}>
            {title}
          </Heading>
        )}

        {modal && (
          <Button width="100%" variant={"modal"} onClick={onOpen}>
            <Heading
              fontSize="sm"
              textAlign={"center"}
              color={"text.primary"}
              whiteSpace="normal"
            >
              {title}
            </Heading>
          </Button>
        )}
        <ImprovesModal
          isOpen={isOpen}
          onClose={onClose}
          title={title}
          image={image}
          modal={modal}
        />

        {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="lg" textAlign={"center"}>
              {title}
            </ModalHeader>
            <ModalBody>
              <Center>
                <Image
                  src={image}
                  alt={title}
                  borderRadius={10}
                  objectFit="cover"
                  paddingBottom={5}
                />
              </Center>
              <Text>{modal}</Text>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onClose}>
                Salir
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal> */}

        <Text color={"text.primary"}>{description}</Text>
      </CardBody>
    </Card>
  );
};
export default PhotoCard;
