import { Flex } from "@chakra-ui/react";
import InformativeSection from "../../components/molecules/InformativeSection";
import CardNoImage from "../../components/molecules/CardNoImage";
import PhotoCard from "../../components/molecules/PhotoCard";
import InformativeSectionContainer from "../../components/organisms/InformativeSectionContainer";
import MainHeader from "../../components/organisms/MainHeader";
import { AboutSectionContent } from "../../constants/aboutSectionContent";
import { communityCardContent } from "../../constants/communityCardContent";
import { teamCardContent } from "../../constants/teamCardContent";
import { InformativeCardProps } from "../../types/InformativeCardProps";
import { ModalCardProps } from "../../types/ModalCardProps";

const MainHeaderContent = {
  title: "Gestionamos comunidades,\n construimos confianza",
};

const AboutScreen = () => {
  return (
    <Flex flexDirection={"column"}>
      <MainHeader
        classNameProp={"about-hero-section"}
        title={MainHeaderContent.title}
      />
      <Flex
        flexDirection={"column"}
        py={8}
        px={9}
        gap={16}
        w={"100%"}
        justify={"center"}
        align={"center"}
      >
        <InformativeSectionContainer
          variant="left-image"
          imageUrl={AboutSectionContent[0].image}
          section1={AboutSectionContent[0]}
        />
        <InformativeSectionContainer
          variant="right-image"
          imageUrl={AboutSectionContent[1].image}
          section1={AboutSectionContent[1]}
        />

        <InformativeSection
          title={AboutSectionContent[4].title}
          description={AboutSectionContent[4].description}
        />
        <Flex
          flexDirection={"column"}
          gap={4}
          p={8}
          justify={"center"}
          align={"center"}
        >
          {teamCardContent.map((item: ModalCardProps) => (
            <CardNoImage title={item.title} description={item.description} />
          ))}
        </Flex>
        <InformativeSectionContainer
          variant="double-section"
          section1={AboutSectionContent[2]}
          section2={AboutSectionContent[3]}
        />
        <InformativeSection
          title={AboutSectionContent[5].title}
          description={AboutSectionContent[5].description}
        />

        <Flex
          flexDirection={"row"}
          justify={"center"}
          align={"center"}
          wrap={"wrap"}
          gap={12}
          maxW={"1020px"}
        >
          {communityCardContent.map((item: InformativeCardProps) => (
            <PhotoCard image={item.image} title={item.title} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
export default AboutScreen;
