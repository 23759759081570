import {
  Button,
  Center,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  image: string | undefined;
  modal: string | undefined;
}

const ImprovesModal = ({
  isOpen,
  onClose,
  title,
  image,
  modal,
}: ModalProps) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" textAlign={"center"}>
            {title}
          </ModalHeader>
          <ModalBody>
            <Center>
              <Image
                src={image}
                alt={title}
                borderRadius={10}
                objectFit="cover"
                paddingBottom={5}
              />
            </Center>
            <Text>{modal}</Text>
          </ModalBody>

          <ModalFooter>
            <Button size={"sm"} mr={3} onClick={onClose}>
              Salir
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ImprovesModal;
